<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="报价单列表"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading" :showSummary="true" :keys="['totalQuotes','ctaliRebate','commercialRebate']">
      <template #header="params">
        <div class="search_box">
          <el-form :inline="true" :model="searchData" size="small" label-position="left">
            <el-form-item label="询报价编号">
              <el-input v-model="searchData.inquiryNo" placeholder="询报价编号" clearable></el-input>
            </el-form-item>
            <el-form-item label="投保类型">
              <el-select v-model="searchData.businessType" placeholder="投保类型" clearable>
                <el-option label="新保" value="1"></el-option>
                <el-option label="续保" value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="意向保司">
              <el-input v-model="searchData.orgName" placeholder="意向保司" clearable></el-input>
            </el-form-item>


            <el-form-item label="投保状态">
              <el-select v-model="searchData.status" placeholder="投保状态" clearable>
                <el-option label="提交信息" value="0"></el-option>
                <el-option label="待报价" value="1"></el-option>
                <el-option label="已报价待同意" value="2"></el-option>
                <el-option label="已同意待核验" value="3"></el-option>
                <el-option label="已核验待支付" value="4"></el-option>
                <el-option label="已支付待出单" value="5"></el-option>
                <el-option label="已完成" value="6"></el-option>
                <el-option label="已取消" value="7"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="投保人手机号">
              <el-input v-model="searchData.customerMobile" placeholder="投保人手机号" clearable></el-input>
            </el-form-item>
            <el-form-item label="投保人">
              <el-input v-model="searchData.customer" placeholder="投保人" clearable></el-input>
            </el-form-item>
            <el-form-item label="邀请码">
              <el-input v-model="searchData.invitationCode" placeholder="邀请码" clearable></el-input>
            </el-form-item>
            <el-form-item label="邀请人手机号">
              <el-input v-model="searchData.inviterMobile" placeholder="邀请人手机号" clearable></el-input>
            </el-form-item>
            <el-form-item label="车牌">
              <el-input v-model="searchData.licensePlate" placeholder="车牌" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="pageHandler(params, 1)">查询</el-button>
              <el-button @click="pageHandler(params, 1,'reset')" type="primary">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-table-column label="报价编号" fixed="left" prop="inquiryNo" min-width="150"></el-table-column>
      <el-table-column label="投保人" prop="customer" min-width="150"></el-table-column>
      <el-table-column label="投保人手机号" prop="customerMobile" min-width="150"></el-table-column>
      <el-table-column label="投保类型" prop="businessType">
        <template slot-scope="scope">
          {{scope.row.businessType | businessTypeFilter}}
        </template>
      </el-table-column>
      <el-table-column label="车牌" prop="licensePlate" min-width="150"></el-table-column>
      <el-table-column label="能源类型" prop="energyType">
        <template slot-scope="scope">
          {{scope.row.energyType | energyTypeFilter}}
        </template>
      </el-table-column>
      <el-table-column label="邀请人手机号" prop="inviterMobile" min-width="150"></el-table-column>
      <el-table-column label="邀请码" prop="invitationCode" min-width="150"></el-table-column>
      <el-table-column label="意向保司" prop="orgName" min-width="150"></el-table-column>
      <el-table-column label="投保地区" prop="regionDesc" min-width="150"></el-table-column>
      <el-table-column label="总保费" prop="totalQuotes"></el-table-column>
      <el-table-column label="交强险返利" prop="ctaliRebate"  min-width="100"></el-table-column>
      <el-table-column label="商业险返利" prop="commercialRebate"  min-width="100"></el-table-column>
      <el-table-column label="投保状态" prop="status" min-width="150">
        <template slot-scope="scope">
          {{scope.row.status | stateFilter}}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="ctime" min-width="150"></el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="120">
        <template slot-scope="scope">

          <router-link target="_blank" :to="`/insurance/insuranceQuotation/view/${scope.row.inquiryNo}`">
            <el-link type="primary">查看</el-link>
          </router-link>
        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
import FileUpload from "@/components/scope/file-upload.vue";
import Template from "@/views/dashboard/template.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Template, FileUpload },
  data() {
    //这里存放数据
    return {
      listData: [],
      // 搜索数据
      searchData: {
        inquiryNo: null,
        businessType: null,
        customer: null,
        customerMobile: null,
        invitationCode: null,
        inviterMobile: null,
        licensePlate: null,
        status: null,
      },
      listLoading: false,
      pageData: {
        num: 1,
        size: 5,
        total: 0
      },
    };
  },
  filters: {
    stateFilter: function(val) {
      // 状态 0-提交信息 1-待报价 2-已报价待同意 3-已同意待核验 4-已核验待支付 5-已支付待出单 6-已完成 7-已取消
      switch (val) {
        case 0:
          return "待报价";
        case 1:
          return "待报价";
        case 2:
          return "已报价待同意";
        case 3:
          return "已同意待核验";
        case 4:
          return "已核验待支付";
        case 5:
          return "已支付待出单";
        case 6:
          return "已完成";
        case 7:
          return "已取消";
      }
      return "-";
    },
    businessTypeFilter(val){
      switch (val) {
        case 1:
          return "新保";
        case 2:
          return "续保";
      }
      return "-"
    },
    energyTypeFilter(val){
      switch (val) {
        case 1:
          return "燃油";
        case 2:
          return "新能源";
      }
      return "-"
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getDataList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        inquiryNo: null,
        businessType: null,
        customer: null,
        customerMobile: null,
        invitationCode: null,
        inviterMobile: null,
        licensePlate: null,
        status: null,
      }
    },

    getDataList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('insurance/getBxInquiryList',{
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          console.log(res)
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.page_box {
  padding: 0 20px;
  .search_box {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
  }
  .table_nav_bar{
    width: 100%;
    background: #fff;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    .el-button{
      margin-right: 10px;
    }
  }
  .table_row_rule{
    word-break: break-all; /** 换行，长单词会分成两行显示 **/
    text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
    display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    -webkit-line-clamp: 1; /** 显示的行数 **/
    overflow: hidden;  /** 隐藏超出的内容 **/;
  }
}
.handle_btn{
  padding: 0;
}
.channel_item{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  >.el-input:nth-child(1){
    width: 260px;
  }
}
.channel_item:last-child{
  margin: 0;
}
.popularize_list_qrcode{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qrcode_img_src{
  position: absolute;
  top: -10000%;
  z-index: -10000;
}
.search_item{
  display: flex;
  align-items: center;
  >div:first-child{
    flex-shrink: 0;
  }
}
.insurance_logo_link{
  width: 72px;
  height: 72px;
  display: block;
}
.insurance_dialog{
  ::v-deep .el-dialog__body{
    padding: 10px 20px !important;
  }
  .el-input{
    width: 50%;
  }
  .insurance_dialog_module{
    .dialog_module_title{
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .title_line{
        width: 4px;
        height: 16px;
        background: #409EFF;
        margin-right: 5px;
        border-radius: 1px;
      }
    }
    .popup_table{
      .el-input{
        width: 100%;
      }
    }
  }
}
::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
  padding: 0 !important;
}
::v-deep .el-table__body tbody tr td:first-of-type {
  padding-left: 0px !important;
}
</style>
